import styles from './Website.module.scss';
import { useState } from 'react';
import { FC } from 'react';
import { Navbar, createStyles } from '@mantine/core';
import { LinksGroup } from './NavbarLinksGroup';
import { IconVideo, IconVersions, IconFileAnalytics, IconUser, IconHelp, IconBulb } from '@tabler/icons-react';
import cn from 'classnames';
import { OnboardingStepper } from './OnboardingStepper/OnboardingStepper';

interface WebsitesProps {
  collapse: boolean;
}

const mainLinksData = [
  { label: 'Library', icon: IconVideo, to: '/library/inbox', baseRoute: '/library' },
  { label: 'Widgets', icon: IconVersions, to: '/widgets', baseRoute: '/widgets' },
  { label: 'Analytics', icon: IconFileAnalytics, to: '/analytics', baseRoute: '/analytics' },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  linksInner: {
    paddingTop: 15,
  },

  footer: {
    border: 'none',
    paddingTop: 16,
    paddingBottom: 38,
  },
}));

export const Websites: FC<WebsitesProps> = ({ collapse }) => {
  const { classes } = useStyles();

  const [growSection, setGrowSection] = useState<string>('links');

  const links = mainLinksData.map((item) => (
    <LinksGroup isCollapsed={collapse} {...item} key={item.label} />
  ));

  const handleOpenIntercom = () => {
    window.Intercom('open');
  }

  return (
    <Navbar height={'100%'} width={!collapse ? { sm: 268 } : { xs: 44 }} className={classes.navbar}>
      <Navbar.Section grow={growSection === 'links'}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <OnboardingStepper isCollapsed={collapse} setGrowSection={setGrowSection} />

      <Navbar.Section>
        <LinksGroup
          isCollapsed={collapse}
          to="https://chatpoint.ducalis.io/roadmap"
          label="Feature Request"
          icon={IconBulb}
          openInNewTab
        />

        <LinksGroup
          isCollapsed={collapse}
          label="Contact Support"
          icon={IconHelp}
          onClick={handleOpenIntercom}
        />
      </Navbar.Section>

      <Navbar.Section className={cn(classes.footer, styles.account)}>
        <LinksGroup
          isCollapsed={collapse}
          to="/account"
          label="Account"
          icon={IconUser}
          baseRoute="/account"
        />
      </Navbar.Section>
    </Navbar>
  );
};
